import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/Login.vue'),
    },
    {
        path: '/userHome', // 点击侧边栏跳到一个单独的路由页面，需要定义，层级和其他顶级路由一样
        name: 'userHome',
        meta: { title: '用户主页' },
        component: () => import('../views/UserHome.vue'),
        children: [
            {
                name: 'DiaryList',
                path: '/DiaryList',
                meta: { title: '主页' },
                component: () => import('../components/userHome/DiaryList.vue'),
            },
            {
                name: 'MessageList',
                path: '/MessageList',
                meta: { title: '消息' },
                component: () => import('../components/userHome/MessageList.vue'),
            },
            {
                name: 'UserProfile',
                path: '/UserProfile',
                meta: { title: '个人中心' },
                component: () => import('../components/user/UserProfile.vue'),
            },
            {
                name: 'noteList',
                path: '/noteList',
                meta: { title: '笔记' },
                component: () => import('../components/notes/noteList.vue'),
            },
            {
                path: '/SonBlank',
                name: 'SonBlank',
                meta: { title: '' },
                component: () => import('../components/SonBlank.vue'),
            }],
    },
    {
        path: '/Blank',
        name: 'Blank',
        meta: { title: '' },
        component: () => import('../components/Blank.vue'),
    },
    {
        path: '/404',
        name: '404',
        meta: { title: '404' },
        component: () => import('../components/404.vue'),
    },
    { path: '/', redirect: '/home' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '主页' },
        component: () => import('../views/Home.vue'),
    },
    t1: {
        path: 't1',
        name: 't1',
        meta: { title: '表格' },
        component: () => import('../views/T1.vue'),
    },
    logRecord: {
        path: 'logRecord',
        name: 'logRecord',
        meta: { title: '日志记录' },
        component: () => import('../views/logRecord.vue'),
    },
    userList: {
        path: 'userList',
        name: 'userList',
        meta: { title: '用户列表' },
        component: () => import('../views/userList.vue'),
    },
    scanLoginLog: {
        path: 'scanLoginLog',
        name: 'scanLoginLog',
        meta: { title: '扫码登录日志' },
        component: () => import('../views/ScanLoginLog.vue'),
    },
    password: {
        path: 'password',
        name: 'password',
        meta: { title: '修改密码' },
        component: () => import('../views/Password.vue'),
    },
    msg: {
        path: 'msg',
        name: 'msg',
        meta: { title: '通知消息' },
        component: () => import('../views/Msg.vue'),
    },
    userinfo: {
        path: 'userinfo',
        name: 'userinfo',
        meta: { title: '用户信息' },
        component: () => import('../views/UserInfo.vue'),
    },
}


const createRouter = () => new Router({
    routes: commonRoutes,
})

const router = createRouter()

router.beforeEach((to, from, next) => {
    // 在这里执行你的逻辑，比如权限判断、登录状态检查等
    if (to.path === '/login') {
        next()
    } else {
        // 权限校验
        const currentUser = JSON.parse(localStorage.getItem('currentUser'))
        if (!currentUser) {
            next({ path: '/login' })
        }
        if (to.matched.some(record => record.path === '/userHome')) {
            // 如果是跳转到userHome及其子路由，则不进行拦截
            next()
        } else if (currentUser.isAdmin === 'true') {
            next()
        } else {
            next({ path: '/userHome' })
        }
    }
})


export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router