/* eslint-disable max-len */
import Vue from 'vue'
import axios from 'axios'
import ViewUI from 'view-design'
import ElementUI from 'element-ui'
import Viewer from 'v-viewer'


// eslint-disable-next-line import/no-extraneous-dependencies
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import App from './App'
import store from './store'
import router from './router'
import 'view-design/dist/styles/iview.css'
import 'element-ui/lib/theme-chalk/index.css'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'viewerjs/dist/viewer.css'

import './permission'


Vue.config.productionTip = false
// 创建事件总线并附加到 Vue 的原型上
Vue.prototype.$eventBus = new Vue()

Vue.use(autoAnimatePlugin)
Vue.use(ViewUI)
Vue.use(ElementUI)
Vue.use(Viewer)
Viewer.setDefaults({
    Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' },
})
Vue.prototype.$axios = axios

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})
